import React, { Component, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { BsCheckCircleFill, BsFillExclamationCircleFill } from 'react-icons/bs';
import { toasterAlert, toasterRemove } from '../redux/toasterSlice';

const Alert: React.FC = () => {
    const toaster = useSelector((state: RootState) => state.alert.toaster)
    const [list, setList] = useState(toaster);
    const dispatch = useDispatch()

    useEffect(() => {
        if (toaster.length) {
            setList([...toaster]);
        }
    }, [toaster]);

    const deleteToast = (id: any) => {
        const listItemIndex = list.findIndex(e => e.id === id);
        list.splice(listItemIndex, 1);

        const toastListItem = toaster.filter(e => e.id !== id);
        // toaster = toastListItem;
        dispatch(toasterRemove(id))
        setList([...list]);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (toaster.length && list.length) {
                deleteToast(toaster[0].id);
            }
        }, 3000);

        return () => {
            clearInterval(interval);
        }
    }, [toaster, list]);

    return (
        <>
        <div className={'notification-container top-right'}>
        {
            list !== undefined && list.map((toast: any, key) => {
                return (
                    <div
                        key={`toast_${key}`}
                        className={'notification toast top-right'}
                        style={{ backgroundColor: toast.type === 'error' ? '#d9534f' : '#5cb85c' }}
                    >
                        <button onClick={() => deleteToast(toast.id)}>
                            X
                        </button>
                        <div className="d-flex">
                            <div className="notification-image">
                                {toast.type === 'error' ? <BsFillExclamationCircleFill /> : <BsCheckCircleFill />}
                            </div>
                            <div>
                                <p className="notification-title">{toast.title}</p>
                                <p className="notification-message">
                                    {toast.message}
                                </p>
                            </div>
                        </div>
                    </div>
                    )
                })
        }
        </div>
        </>
    );
}

export default Alert;
