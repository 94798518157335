const API_URL = process.env.REACT_APP_API_URL;
const prefixRouteV1 = (route) => `${API_URL}${route}`
const prefixRouteDashboard = (route) => prefixRouteV1(`dashboard/${route}`)
const prefixRouteReport = (route) => prefixRouteV1(`report/${route}`)
const prefixRoutePatient = (route) => prefixRouteV1(`patient/${route}`)
const prefixRouteNewPatient = (route) => prefixRouteV1(`patientnew/${route}`)

const prefixRouteReportApi = (route) => prefixRouteV1(`api/report/${route}`)
const prefixRouteApi = (route) => prefixRouteV1(`api/${route}`)

const apiRouter = {
 // Auth
  LOGIN: prefixRouteV1('auth'),
  SIGNOUT: prefixRouteV1('auth/signout'),
  CHANGEPASSWORD: prefixRouteV1('auth/changepassword'),

 // Comment
  COMMENT: prefixRouteV1('patient/measurement/raw/comment'),

  // Dashboard

  DASHBOARD: prefixRouteV1('dashboard'),
  DASHBOARD_REPORTDATA: prefixRouteDashboard('overview'),
  DASHBOARD_CHARTDATA: prefixRouteDashboard('chart'),
  DB_MEASUREMENT_CHART: prefixRouteDashboard('chart/measurement'),
  DB_TIMING_CHART: prefixRouteDashboard('chart/timing'),
  DB_TASK_CHART: prefixRouteDashboard('chart/task'),

  // Tasks Beta
  TASK_BASE_URL: prefixRouteV1('taskes'),

  // User

  USER_BASE_URL: prefixRouteV1('user'),
  GET_USER_TYPE: prefixRouteV1('user/type'),

  GROUP_BASE_URL: prefixRouteV1('group'),
  // reports
  REPORT_BASE_URL: prefixRouteV1('report'),
  REPORT_BILLING: prefixRouteReport('billing'),
  REPORT_BILLING_PDF: prefixRouteReport('billing/pdf'),
  REPORT_BILLING_PAID: prefixRouteReport('billing/paid'),
  // REPORT_COMPLIANCE: prefixRouteReport('compliance'),
  REPORT_COMPLIANCE: prefixRouteV1('patient/measurement/trend?patientId=108&type=pulse,oxygen,diastolic,systolic,weight,inr,preprandial,postprandial&trim=10&timeframe=all'),
  REPORT_KPI: prefixRouteReport('kpi'),
  INVENTORY_BASE_URL: prefixRouteV1('inventory'),
  SURVEY_BASE_URL: prefixRouteV1('survey'),
  SURVEY_BASE_API_URL: prefixRouteV1('api/survey'),
  SURVEY_BASE_API_URL2: prefixRouteV1('api/survey2'),

  ORGANIZATION_BASE_URL: prefixRouteV1('organization'),

// https://sbr13f9zd3.execute-api.us-west-2.amazonaws.com/v1/patient/measurement/trend?patientId=108&type=pulse,oxygen,diastolic,systolic,weight,inr,preprandial,postprandial&trim=10&timeframe=all
  // Patients
  PATIENT_BASE_URL: prefixRouteV1('patient'),
  PATIENT_GRID: prefixRoutePatient('grid'),
  PATIENT_TIMING_BASE_URL: prefixRoutePatient('timing'),
  PATIENT_EVENTS: prefixRoutePatient('event'),
  PATIENT_PERIOD: prefixRoutePatient('period'),
  PATIENT_REPORT_BY_TYPE: prefixRoutePatient('measurement/raw'),
  PATIENT_TREND: prefixRouteV1('api/patient/measurement/trend'),
  PATIENT_BACKDATE: prefixRoutePatient('backdate'),

  // Events

  EVENT_BASE_URL: prefixRouteV1('event'),

  // Device

  DEVICE_BASE_URL: prefixRouteV1('device'),

  // PATIENT = patient/grid
  DROPDOWN_BASE_URL: prefixRouteV1('api/dashboard/dropdown'),
  DEVICE_READING: prefixRouteV1('api/device/reading'),
  ADVOCATE_REPORT: prefixRouteReportApi('advocate'),
  ADVOCATE_MINUTES_REPORT: prefixRouteReportApi('advocate_minutes'),
  REFERRALS_REPORT: prefixRouteReportApi('administration/referrals'),
  NEW_PATIENT_REPORT: prefixRouteReportApi('administration/newpatient'),
  MINUTES_REPORT: prefixRouteReportApi('administration/minutes'),
  WEEKLY_REPORT: prefixRouteReportApi('administration/weekly'),

  REFERRAL_REASON: prefixRouteApi('referral/referral_reasons'),
  SERVICE_RECOMMENDATION: prefixRouteApi('referral/service_recommendations'),
  INVENTORY_API_BASE_URL: prefixRouteApi('inventory'),
  SERVICES: prefixRouteApi('services'),

  CAREPLAN_API_URL: prefixRouteV1('api/careplan'),
  PATIENT_FAMILY_API_URL: prefixRouteV1('api/user/patientfamily'),
  SMS_API_BASE_URL: prefixRouteApi('sms'),
  SMS_CHANNEL_API_BASE_URL: prefixRouteApi('get_sms_channel'),
  MEDICATION_API_URL: prefixRouteApi('patient/medication'),
  SMS_PRESET_API_BASE_URL: prefixRouteApi('sms/preset'),
  PERIOD_PROBLEM_REPORT: prefixRouteApi('report/period_problem'),
  ONBOARDED_REPORT: prefixRouteApi('report/onboarded'),
  FIX_PATIENT_PROBLEM: prefixRouteApi('patient/fixperiodissues'),
  ICD_CODE_API_URL: prefixRouteApi('medical_code'),
  BILLABLE_REPORT: prefixRouteApi('report/billable')

}

export default apiRouter
